import Vue, { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import longClick from './directives/longclick'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { configureCompat } from 'vue'

import '@/assets/app.scss'

import ViewContainer from '@/components/layout/ViewContainer.vue'
import MainHeader from '@/components/layout/MainHeader.vue'
import Spinner from '@/components/Spinner.vue'

Vue.component('ViewContainer', ViewContainer)
Vue.component('MainHeader', MainHeader)
Vue.component('Spinner', Spinner)

Vue.directive('longclick', longClick({ delay: 400, interval: 0 }))

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost:8080", "m.dajanarodriguez.app", /^\//],
      }),
    ],
    debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
    tracesSampleRate: 0.2,
    tracingOptions: {
      trackComponents: true,
    },
    // Vue specific
    logErrors: process.env.NODE_ENV === 'production' ? false : true,
    attachProps: true,
    attachStacktrace: true,
  });
}

configureCompat({
  WATCH_ARRAY: false,
  COMPONENT_V_MODEL: false
})

createApp(App).use(router).use(store).mount('#app')
