const state = {
  app: JSON.parse(localStorage.getItem('app')) || null,

  apps: [
    {
      id: 1,
      name: 'Výroba',
      selected: true,
      hasStations: true,
      redirectName: 'station',
      allowedLocations: [1,7,5],
    },
    {
      id: 4,
      name: 'Výroba komponentov',
      hasStations: false,
      redirectName: 'component',
      allowedLocations: [1],
    },
    {
      id: 2,
      name: 'Prototyp',
      hasStations: true,
      redirectName: 'prototype',
      allowedLocations: [1],
    },
    {
      id: 3,
      name: 'Oprava reklamácií',
      hasStations: false,
      redirectName: 'repair',
      allowedLocations: [1],
    },
  ],
}

const actions = {
  selectApplication({ commit }, payload) {
    commit('appSelected', payload)
  },
  resetApplication({ commit }, payload) {
    commit('appReset', payload)
  },
}

const mutations = {
  appSelected(state, payload) {
    state.app = payload

    localStorage.setItem('app', JSON.stringify(payload))
  },
  appReset(state) {
    state.app = null

    localStorage.removeItem('app')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
