import router from '@/router'
import store from '@/store'

const state = {
  id: localStorage.getItem('employee') || null,
  employeeData: JSON.parse(localStorage.getItem('employeeData')) || null,
}

const actions = {
  login({ commit }, payload) {
    commit('login', payload)
  },
  logout({ commit }, payload) {
    commit('logout', payload)
  },
}

const mutations = {
  login(state, payload) {
    state.id = payload.id
    state.employeeData = payload

    localStorage.setItem('employee', payload.id)
    localStorage.setItem('employeeData', JSON.stringify(payload))
  },
  logout(state) {
    state.id = state.employeeData = null

    localStorage.removeItem('employee')
    localStorage.removeItem('employeeData')
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}

router.beforeEach((to, from, next) => {
  if (to.meta.requiredLogin && (!state.id || !store.state.station.id)) {
    next({ name: 'login' })
  }
  next()
})
