import Vue from 'vue'
import Vuex, { createStore } from 'vuex'
import device from './modules/device'
import station from './modules/station'
import employee from './modules/employee'
import application from './modules/application'
import location from './modules/location'

Vue.use(Vuex)

export default createStore({
  modules: {
    device,
    station,
    employee,
    application,
    location
  },
})
