const state = {
  id: localStorage.getItem('location') || null,
}

const actions = {
  selectLocation({ commit }, payload) {
    commit('locationSelected', payload)
  },
  locationReset({ commit }, payload) {
    commit('locationReset', payload)
  },
}

const mutations = {
  locationSelected(state, payload) {
    state.id = payload.id
    state.locationData = payload

    localStorage.setItem('location', payload.id)
    localStorage.setItem('locationData', JSON.stringify(payload))
  },
  locationReset(state) {
    state.id = state.locationData = null

    localStorage.setItem('location', null)
    localStorage.setItem('locationData', null)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}