<template>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
.container {
  min-height: 100vh;
  height: 100%;
}
</style>
