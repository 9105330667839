const state = {
  id: localStorage.getItem('station') || null,
}

const actions = {
  selectStation({ commit }, payload) {
    commit('stationSelected', payload)
  },
  stationReset({ commit }, payload) {
    commit('stationReset', payload)
  },
}

const mutations = {
  stationSelected(state, payload) {
    state.id = payload

    localStorage.setItem('station', payload)
  },
  stationReset(state) {
    state.id = null

    localStorage.setItem('station', null)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}